import React, { useContext, useEffect } from "react";
import ModalLayout from "./Modal";
import { AppContext } from "../context/AppContext";
import { MdClose } from "react-icons/md";
import { square } from "ldrs";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../utils/CustomBtn";

square.register();

function ConnectingModal({ rollLoading, setRollLoading }) {
	const {
		openModalConnect,
		handleModalConnect,
		selectedListing,
		// rollLoading,
		// setRollLoading,
	} = useContext(AppContext);
	const navigate = useNavigate();

	const handleClick = () => {
		navigate(`/selected-wallet/${selectedListing.name}`);
		handleModalConnect();
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (rollLoading) {
				setRollLoading(false);
			}
		}, 2000);

		return () => clearTimeout(timer);
	}, [rollLoading, setRollLoading]);
	return (
		<>
			{openModalConnect && (
				<ModalLayout>
					<div className="space-y-6">
						<div className="flex w-full items-center justify-end">
							<button
								onClick={handleModalConnect}
								className="text-3xl bg-black text-white p-1 rounded">
								<MdClose />
							</button>
						</div>

						{rollLoading ? (
							<div className="flex flex-col items-center justify-center gap-y-5 py-4">
								{selectedListing.image_url && (
									<img
										src={selectedListing.image_url.sm}
										alt=""
										className="rounded-lg h-[56px] w-[56px] object-cover "
									/>
								)}
								<div>
									<p className="text-center font-bold text-xl text-secondary">
										Connecting to {selectedListing.name}
									</p>
									<p className="text-center text-sm">Please wait</p>
								</div>
								<Spinner />
							</div>
						) : (
							<div className="flex flex-col items-center justify-center gap-y-5 py-4">
								{selectedListing.image_url && (
									<img
										src={selectedListing.image_url.sm}
										alt=""
										className="rounded-lg h-[56px] w-[56px] object-cover "
									/>
								)}
								<div>
									<p className="text-center font-bold text-xl text-red-700">
										An Error Occurred
									</p>
									<p className="text-center">Try another option</p>
								</div>

								<CustomBtn size="xl" onClick={handleClick}>
									Connect Manually
								</CustomBtn>
							</div>
						)}
					</div>
				</ModalLayout>
			)}
		</>
	);
}

export default ConnectingModal;

export const Spinner = () => {
	return (
		<l-square
			size="35"
			stroke="5"
			stroke-length="0.25"
			bg-opacity="0.1"
			speed="1.2"
			color="black"></l-square>
	);
};
