import React from "react";

function CustomBtn({ size, color, children, ...props }) {
	return (
		<button
			className={`h-[52px] rounded-[10px] focus:shadow-sm text-white font-bold border-l border-t border-b-[2px] border-r-[3px] border-black uppercase px-[10px] py-[9px] min-w-[120px] min-h-[45px] ${
				color === "dark" ? "bg-black" : "bg-purple-500"
			}  ${
				size === "sm"
					? "px-3"
					: size === "lg"
					? ""
					: size === "xl"
					? "w-full"
					: ""
			}  `}
			{...props}>
			{children}
		</button>
	);
}

export default CustomBtn;
