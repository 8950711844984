import React, { useState } from "react";
import { LogoIcon } from "../../assets/Export";
import { PageLayout } from "../../layout/pageLayout";
import EthIcon from "../../assets/images/svg-icons/ETH.svg";
import USDTIcon from "../../assets/images/svg-icons/usdt.svg";
import USDIcon from "../../assets/images/svg-icons/usd.svg";
import CardIcon from "../../assets/images/svg-icons/card.svg";
import { useNavigate } from "react-router-dom";
import IconLogo from "../../assets/icon-logo.svg";
import CustomBtn from "../../utils/CustomBtn";

function Hero() {
	const [selectedBtn, setSelectedBtn] = useState("ETH");
	const [inputAmount, setInputAmount] = useState(0);
	const router = useNavigate();

	const handleInputChange = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			setInputAmount(value);
		}
	};
	return (
		<>
			<div className="hero min-h-screen w-full pt-28 lg:pt-10">
				<PageLayout>
					<div className="lg:hidden">
						<LogoIcon />
					</div>

					<div className="grid grid-cols-1 lg:grid-cols-3">
						<div className="lg:order-2 lg:col-span-1">
							<div className="w-full max-w-[420px] bg-[#FFF5E9] border-[2px] border-black rounded-[4px] pb-6 ">
								<div className="space-y-4">
									<div className="bg-purple-500 w-full border-b border-black relative h-[30px] flex ">
										<img
											src="https://playdoge.io/assets/images/svg-icons/widget-title.svg"
											alt=""
											className="w-full h-auto "
										/>
										<div className="absolute top-0 left-0 right-0 text-white font-medium bg-purple-500 w-full max-w-[70%] mx-auto flex items-center justify-center ">
											<p className="text-center">Buy $PLAY Tokens</p>
										</div>
									</div>

									{/* Time  */}
									<div className="py-3 px-[15px] w-full flex items-center justify-center gap-2">
										<div className="text-center bg-[#E4ADFF] border border-r-[2px] border-black rounded-[5px] py-[6px] px-[12px] w-full text-black ">
											<p className="">00</p>
											<p className="">Days</p>
										</div>
										<div className="text-center bg-[#92D8FF] border border-r-[2px] border-black rounded-[5px] py-[6px] px-[12px] w-full text-black ">
											<p className="">00</p>
											<p className="">Hours</p>
										</div>
										<div className="text-center bg-[#8FFFE4] border border-r-[2px] border-black rounded-[5px] py-[6px] px-[12px] w-full text-black ">
											<p className="">00</p>
											<p className="">Mins</p>
										</div>
										<div className="text-center bg-[#FFED8E] border border-r-[2px] border-black rounded-[5px] py-[6px] px-[12px] w-full text-black ">
											<p className="">00</p>
											<p className="">Secs</p>
										</div>
									</div>

									<div className="w-[90%] mx-auto rounded-[30px] border border-black p-[4px] roundeprice  ">
										<div className="rounded-[30px] h-[24px] border-[2px] border-black bg-white overflow-hidden relative ">
											<div className="bg-purple-500 w-[80%] h-full "></div>
											<div className="absolute top-0 bottom-0 w-full text-white text-sm">
												<p className="text-center">UNTIL PRICE INCREASE</p>
											</div>
										</div>
									</div>

									<div className="px-4 text-center space-y-4">
										<p>
											<span className="font-bold">USDT RAISED:</span>{" "}
											$5,392,637.45 / $5,999,115
										</p>
										<p>YOUR PURCHASED PLAY = 0</p>
										<p>YOUR STAKEABLE PLAY = 0</p>
									</div>

									<div className="px-4">
										<div className="relative">
											<p className="text-line text-center dashTitle font-medium">
												1 PLAY = $0.00516
											</p>
										</div>
									</div>

									<div className="px-4 space-y-4">
										<div className="grid grid-cols-3 gap-x-3">
											{selectBtnList.map((item, index) => {
												return (
													<CustomSelectBtn
														icon={item.icon}
														text={item.text}
														selectedBtn={selectedBtn}
														setSelectedBtn={setSelectedBtn}
													/>
												);
											})}
										</div>

										{/* Inputs  */}
										<div className="grid grid-cols-1 lg:grid-cols-2 gap-3">
											<div className="w-full space-y-2">
												<div className="w-full flex items-center justify-between text-sm">
													<p>{selectedBtn} you pay</p>
													<p>Max</p>
												</div>
												<div className="relative">
													<input
														type="tel"
														className="relative min-h-[45px] py-[8px] px-[12px] w-full border border-b-[2px] border-r-[3px] border-black rounded-none text-black bg-white focus:outline-none"
														value={inputAmount}
														onChange={handleInputChange}
													/>
													<div className="w-[26px] h-[26px] absolute right-3 top-[20%]">
														<img
															src={
																selectedBtn === "ETH"
																	? EthIcon
																	: selectedBtn === "USDT"
																	? USDTIcon
																	: selectedBtn === "USD"
																	? USDIcon
																	: ""
															}
															alt=""
															className="w-full h-full rounded-full"
														/>
													</div>
												</div>
											</div>
											<div className="w-full space-y-2">
												<div className="w-full flex items-center justify-between text-sm">
													<p>PLAY you receive</p>
												</div>
												<div className="relative">
													<input
														type="text"
														className="relative min-h-[45px] py-[8px] px-[12px] w-full border border-b-[2px] border-r-[3px] border-black rounded-none text-black bg-white focus:outline-none"
														value={
															selectedBtn === "ETH"
																? inputAmount * 153986
																: inputAmount * 45
														}
														readOnly
													/>
													<div className="w-[28px] h-[28px] absolute right-3 top-[20%]">
														<img src={IconLogo} alt="" />
													</div>
												</div>
											</div>
										</div>

										<CustomBtn
											onClick={() => router("/select-wallet")}
											size="xl">
											Claim $PLAYDOGE
										</CustomBtn>
									</div>
								</div>
							</div>
						</div>
						<div className="lg:order-1 lg:col-span-2"></div>
					</div>
				</PageLayout>
			</div>
		</>
	);
}

export default Hero;

const selectBtnList = [
	{
		icon: EthIcon,
		text: "ETH",
	},
	{
		icon: USDTIcon,
		text: "USDT",
	},
	{
		icon: CardIcon,
		text: "USD",
	},
];

function CustomSelectBtn({ icon, text, selectedBtn, setSelectedBtn }) {
	return (
		<button
			onClick={() => setSelectedBtn(text)}
			className={`w-full flex items-center justify-center gap-x-2 h-[50px] rounded-none transition-all duration-500 ${
				selectedBtn === text
					? "bg-black text-white"
					: "bg-white text-black border border-b-[2px] border-r-[3px] border-black "
			}`}>
			<div className="w-[26px] h-[26px] ">
				<img src={icon} alt={text} className="w-full h-full rounded-full" />
			</div>
			<span className="">{text}</span>
		</button>
	);
}
