import React from "react";
import Hero from "../components/screens/Hero";

function Landing() {
	return (
		<div>
			<Hero />
		</div>
	);
}

export default Landing;
