import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { navMenu } from "./Header";
import { PageLayout } from "../../layout/pageLayout";
import CustomBtn from "../../utils/CustomBtn";

function MobileMenu({ openMenu, setOpenMenu }) {
	const router = useNavigate();
	return (
		<div
			className={`fixed inset-0 z-40 bg-yellow-500 transition-all duration-700 ${
				openMenu ? "left-0" : "left-[100%]"
			}`}>
			<PageLayout>
				<div className="pt-28">
					<ul className="lg:hidden ">
						{navMenu.map((item, index) => (
							<MenuList key={index} item={item} setOpenMenu={setOpenMenu} />
						))}
						<CustomBtn
							onClick={() => {
								router("/select-wallet");
								setOpenMenu(false);
							}}
							size="xl">
							Claim $PLAYDOGE
						</CustomBtn>
					</ul>
				</div>
			</PageLayout>
		</div>
	);
}

export default MobileMenu;

function MenuList({ item, setOpenMenu }) {
	const path = useLocation();
	const router = useNavigate();
	return (
		<li
			onClick={() => {
				router(`${item.url}`);
				setOpenMenu(false);
			}}
			className={`w-full py-4 px-2 rounded text-black text-lg ${
				path === item.url ? "" : ""
			} `}>
			<span>{item.name}</span>
		</li>
	);
}
