import React from "react";
import { PageLayout } from "../../layout/pageLayout";
import Logo from "../../assets/images/svg-icons/logo.svg";
import CustomBtn from "../../utils/CustomBtn";
import {
	CloseIcon,
	MenuIcon,
	TelegramIcon,
	Twitter,
} from "../../assets/Export";
import Flag from "../../assets/flag.svg";
import MobileMenu from "./MobileMenu";

function Header() {
	const [openMenu, setOpenMenu] = React.useState(false);

	return (
		<>
			<div className="header-nav sticky w-full top-0 z-50 py-5 lg:py-3 bg-yellow-500 border-b-[6px] border-gray-500">
				<PageLayout>
					<div className="flex items-center justify-between ">
						<div>
							<a href="/">
								<img src={Logo} alt="" />
							</a>
						</div>
						<div>
							<ul className="hidden lg:flex gap-4 text-gray-800 text-[14px]">
								{navMenu.map((item, index) => (
									<MenuList key={index} item={item} />
								))}
							</ul>
						</div>
						<div className="hidden lg:flex items-center gap-x-6">
							<a href="/select-wallet">
								<CustomBtn>Claim $PLAYDOGE</CustomBtn>
							</a>
							<div className="flex items-center gap-x-2">
								<Twitter style={{ width: "30px", height: "30px" }} />
								<TelegramIcon style={{ width: "30px", height: "30px" }} />
							</div>
							<div className="flex items-center gap-x-2">
								<div className="w-[25px] h-[25px] rounded-full ">
									<img
										src={Flag}
										alt=""
										className="rounded-full h-full w-full object-cover"
									/>
								</div>
								<span>EN</span>
							</div>
						</div>

						<button
							onClick={() => setOpenMenu(!openMenu)}
							className="text-white focus:outline-none lg:hidden">
							{!openMenu ? <MenuIcon /> : <CloseIcon />}
						</button>
					</div>
				</PageLayout>
			</div>
			<MobileMenu openMenu={openMenu} setOpenMenu={setOpenMenu} />
		</>
	);
}

export default Header;

function MenuList({ item }) {
	return (
		<li>
			<a href={item.url}>{item.name}</a>
		</li>
	);
}

export const navMenu = [
	{
		name: "Staking",
		url: "/",
	},
	{
		name: "About",
		url: "/",
	},
	{
		name: "Roadmap",
		url: "/",
	},
	{
		name: "Tokenomics",
		url: "/",
	},
	{
		name: "FAQ",
		url: "/",
	},
	{
		name: "Audit",
		url: "/",
	},
];
